import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import MUITable from '@mui/material/Table';
import MUITableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

import TableRow from './table-row';
import '../styles/table.scss';

/**
 * Display the list view of items / records in the data set manager.
 */
class Table extends React.Component {
    constructor(props) {
        super(props);

        const { list, page, rowsPerPage, dataSet } = props;
        const slicedList = this.getSlicedList(list, page, rowsPerPage);
        const latestOperationId = this.getLatestOperationId(dataSet);
        const showOperationsColumn = this.getShowOperationColumn(slicedList, latestOperationId);
        this.state = {
            latestOperationId,
            slicedList,
            showOperationsColumn
        };

        this.table = React.createRef();
    }

    /**
     * Get that part of the list that is to be displaye on this page.
     * @param {array} list
     * @param {number} page
     * @param {number} rowsPerPage
     * @returns part of the list
     */
    getSlicedList = (list, page, rowsPerPage) => {
        return list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    /**
     * Get the id of the last update operation on this dataset
     * @param {object} dataSet
     * @returns operation id
     */
    getLatestOperationId = (dataSet) => {
        return dataSet && dataSet.latestUpdate ? dataSet.latestUpdate.operationId : null;
    };

    /**
     * Determine whether to show the culomn with mutation indicators.
     * If there are no mutations for this view. The column can be ommited.
     * @param {array} slicedList
     * @param {string} latestOperationId
     * @returns
     */
    getShowOperationColumn = (slicedList, latestOperationId) => {
        let result = false;
        if (latestOperationId) {
            slicedList.forEach((item) => {
                if (item.mutations?.created?.operationId === latestOperationId) result = true;
                if (item.mutations?.updated?.operationId === latestOperationId) result = true;
            });
        }
        return result;
    };

    componentDidUpdate = (prevProps) => {
        const { dataSet = {}, list, page, rowsPerPage } = this.props;

        // Update the latestOperationId in state if the data set is changed.
        if (prevProps.dataSet !== dataSet) {
            this.setState({ latestOperationId: this.getLatestOperationId(this.props.dataSet) });
        }

        // If the list, page or rowsPerPage change, update the slicedList and showOperationsColumn in state.
        if (prevProps.list !== list || prevProps.page !== page || prevProps.rowsPerPage !== rowsPerPage) {
            const slicedList = this.getSlicedList(list, page, rowsPerPage);
            const showOperationsColumn = this.getShowOperationColumn(slicedList, this.state.latestOperationId);
            this.setState({
                slicedList,
                showOperationsColumn
            });
        }
    };

    render() {
        const {
            structure,
            tableLoading,
            page,
            totalItems,
            rowsPerPage,
            dataSet,
            onHandleChangePage,
            displayTypes,
            onHandleEditItem,
            onHandleCopyItem,
            onOpenConfirmDialog,
            onHandleChangeRowsPerPage,
            showRowActions,
            datatCyPrefix
        } = this.props;
        const { latestOperationId, slicedList, showOperationsColumn } = this.state;

        return (
            <React.Fragment>
                <TableContainer className="data-set-manager-table" data-cy={`${datatCyPrefix}-dataSetManagerTable-container`} ref={this.table}>
                    <MUITable stickyHeader={true} className="data-set-manager-table__table" size="small">
                        <TableHead>
                            <MUITableRow>
                                {showOperationsColumn && <TableCell className="data-set-manager-table__header"></TableCell>}
                                {structure.map((item, i) => {
                                    if (!item.hideColumn) {
                                        return (
                                            <TableCell key={i} className="data-set-manager-table__header">
                                                {item.title}
                                            </TableCell>
                                        );
                                    }
                                    return false;
                                })}
                            </MUITableRow>
                        </TableHead>

                        {!tableLoading && (
                            <TableBody>
                                {slicedList.map((listItem, indexList) => (
                                    <TableRow
                                        key={indexList}
                                        listItem={listItem}
                                        structure={structure}
                                        displayTypes={displayTypes}
                                        dataSet={dataSet}
                                        handleEditItem={onHandleEditItem}
                                        handleCopyItem={onHandleCopyItem}
                                        openConfirmDialog={onOpenConfirmDialog}
                                        latestOperationId={latestOperationId}
                                        showOperationsColumn={showOperationsColumn}
                                        ref={this.table}
                                        showRowActions={showRowActions}
                                        datatCyPrefix-cy={datatCyPrefix}
                                    />
                                ))}
                            </TableBody>
                        )}
                    </MUITable>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 25, 100]}
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onHandleChangePage}
                    onRowsPerPageChange={onHandleChangeRowsPerPage}
                />
            </React.Fragment>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    structure: PropTypes.array,
    tableLoading: PropTypes.bool,
    page: PropTypes.number,
    searchTerm: PropTypes.string,
    rowsPerPage: PropTypes.number,
    dataSet: PropTypes.object,
    displayTypes: PropTypes.array,
    onHandleChangePage: PropTypes.func,
    onHandleEditItem: PropTypes.func,
    onOpenConfirmDialog: PropTypes.func,
    onHandleChangeRowsPerPage: PropTypes.func
};

Table.defaultProps = {
    list: [],
    dataSet: {}
};

export default Table;
