import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';

/**
 * Inputs
 */
import { FieldSetAssetGalleryInput } from 'components/input/AssetGalleryInput';
import { FieldSetFeedSelectorInput } from 'components/input/FeedSelectorInput';
import { FieldSetFeedMultiSelectorInput } from 'components/input/FeedMultiSelectorInput';
import { FieldSetAssetSelector } from 'components/input/AssetSelector';
import { FieldSetCheckList } from 'components/input/CheckList';
import { FieldSetColorSelector } from 'components/input/ColorSelector';
import { FieldSetCopy } from 'components/input/Copy';
import { FieldSetCopyMultiLanguage } from 'components/input/CopyMultiLanguage';
import { FieldSetLink } from 'components/input/Link';
import { FieldSetLinkList } from 'components/input/LinkList';
import { FieldSetDatePicker } from 'components/input/DatePicker';
import { FieldSetDateTimePicker } from 'components/input/DateTimePicker';
import { FieldSetDateRangePicker } from 'components/input/DateRangePicker';
import { FieldSetTimePicker } from 'components/input/TimePicker';
import { FieldSetItemList } from 'components/input/ItemList';
import { FieldSetLanguageSelector } from 'components/input/LanguageSelector';
import { FieldSetRadioList } from 'components/input/RadioList';
import { FieldSetRadioListMultiLanguage } from 'components/input/RadioListMultiLanguage';
import { FieldSetRichTextEditor } from 'components/input/RichTextEditor';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import { FieldSetSelectInputMultiLanguage } from 'components/input/SelectInputMultiLanguage';
import { FieldSetSelectVisual } from 'components/input/SelectVisual';
import { FieldSetSwitch } from 'components/input/Switch';
import { FieldSetNumber } from 'components/input/Number';
import { FieldSetDisplay } from 'components/input/Display';
import { FieldSetButtonGroup } from 'components/input/ButtonGroup';
import { FieldSetSlider } from 'components/input/Slider';
import { FieldSetFileInput } from 'components/input/FileInput';
import { FieldSetDecisionTreeOptions } from 'components/input/DecisionTreeOptions';
import { FieldSetCustom } from 'components/input/Custom';
import { FieldSetAutocomplete } from 'components/input/AutoComplete';
import { FieldSetCampaignChannelSelector } from 'components/input/CampaignChannelSelector';
import { FieldSetCampaignCategorySelector } from 'components/input/CampaignCategorySelector';
import { FieldSetCampaignTags } from 'components/input/CampaignTags';
import { FieldSetTransforms } from 'components/input/Transforms';
import { FieldSetFacebookLocation } from 'components/input/FacebookLocation';
import { FieldSetFacebookSearch } from 'components/input/FacebookSearch';
import { FieldSetFacebookPixelId } from 'components/input/FacebookPixelId';
import { FieldSetFacebookCustomEvent } from 'components/input/FacebookCustomEvent';
import { FieldSetFacebookObjective } from 'components/input/FacebookObjective';
import { FieldSetFacebookCampaignBudget } from 'components/input/FacebookCampaignBudget';
import { FieldSetFacebookAdSet } from 'components/input/FacebookAdSet';
import { FieldSetFacebookCampaign } from 'components/input/FacebookCampaign';
import { FieldSetFacebookAge } from 'components/input/FacebookAge';
import { FieldSetMediaSetSelector } from 'components/input/MediaSetSelector';
import { FieldSetCreativeSelector } from 'components/input/CreativeSelector';
import { FieldSetFeedSelect } from 'components/input/FeedSelect';
import { FieldSetGoogleAds } from 'components/input/GoogleAds';
import { FieldSetGoogleAdsLocation } from 'components/input/GoogleAdsLocation';
import { FieldSetSubAccounts } from 'components/input/SubAccounts';
import { FieldSetMultiLevelCheckboxList } from 'components/input/MultiLevelCheckboxList';
import { FieldSetPresetSelector } from 'components/input/PresetSelector';
import { FieldSetDynamicData } from 'components/input/DynamicData';
import { FieldSetDynamicColorList } from 'components/input/DynamicColorList';
import { FieldSetTextSelect } from 'components/input/TextSelect';
import { FieldSetTextSelectMultiLanguage } from 'components/input/TextSelectMultiLanguage';
import { FieldSetOrganisationEntitySelect } from 'components/input/OrganisationEntitySelect';
import { FieldSetOrganisationEntityChecklist } from 'components/input/OrganisationEntityChecklist';
import { Hidden } from 'components/input/Hidden';
import { AlertBox } from 'components/input/AlertBox';
import { Divider } from 'components/input/Divider';
import { FieldSetRepeater } from 'components/input/Repeater';
import { MetaDataInput } from 'components/input/Metadata';

// Customer specific

// #if process.env.customer === 'klm' || process.env.customer === 'afkl' || process.env.customer === 'airfrance'
// import { FieldSetKLMPrimaryOriginSelector } from 'components/customers/KLM/KLMPrimaryOriginSelector';
// import { FieldSetKLMB2BDisplaySelector } from 'components/customers/KLM/KLMB2BDisplaySelector';
// import { KLMDestinationSelector } from 'components/customers/KLM/KLMDestinationSelector';
// import { FieldSetKLMOriginSwitch } from 'components/customers/KLM/KLMOriginSwitch';
// import { FieldSetKLMOriginSelector } from 'components/customers/KLM/KLMOriginSelector';
// #endif

export default {
    // eslint-disable-line
    // Generic input fields
    checkboxList: EditorDynamicDataWrapper(FieldSetCheckList),
    color: EditorDynamicDataWrapper(FieldSetColorSelector),
    text: EditorDynamicDataWrapper(FieldSetCopy),
    textMultiLanguage: EditorDynamicDataWrapper(FieldSetCopyMultiLanguage),
    textSelect: EditorDynamicDataWrapper(FieldSetTextSelect),
    textSelectMultiLanguage: EditorDynamicDataWrapper(FieldSetTextSelectMultiLanguage),
    link: EditorDynamicDataWrapper(FieldSetLink),
    linkList: EditorDynamicDataWrapper(FieldSetLinkList),
    date: EditorDynamicDataWrapper(FieldSetDatePicker),
    datetime: EditorDynamicDataWrapper(FieldSetDateTimePicker),
    time: EditorDynamicDataWrapper(FieldSetTimePicker),
    dateRange: EditorDynamicDataWrapper(FieldSetDateRangePicker),
    display: EditorDynamicDataWrapper(FieldSetDisplay),
    hidden: EditorDynamicDataWrapper(Hidden),
    itemList: EditorDynamicDataWrapper(FieldSetItemList),
    radioList: EditorDynamicDataWrapper(FieldSetRadioList),
    radioListMultiLanguage: EditorDynamicDataWrapper(FieldSetRadioListMultiLanguage),
    richtext: EditorDynamicDataWrapper(FieldSetRichTextEditor),
    richTextEditor: EditorDynamicDataWrapper(FieldSetRichTextEditor),
    select: EditorDynamicDataWrapper(FieldSetSelectInput),
    selectMultiLanguage: EditorDynamicDataWrapper(FieldSetSelectInputMultiLanguage),
    selectVisual: EditorDynamicDataWrapper(FieldSetSelectVisual),
    switch: EditorDynamicDataWrapper(FieldSetSwitch),
    number: EditorDynamicDataWrapper(FieldSetNumber),
    buttonGroup: EditorDynamicDataWrapper(FieldSetButtonGroup),
    slider: EditorDynamicDataWrapper(FieldSetSlider),
    files: EditorDynamicDataWrapper(FieldSetFileInput),
    custom: EditorDynamicDataWrapper(FieldSetCustom),
    autocomplete: EditorDynamicDataWrapper(FieldSetAutocomplete),
    transforms: EditorDynamicDataWrapper(FieldSetTransforms),
    mediaSetSelector: EditorDynamicDataWrapper(FieldSetMediaSetSelector),
    creativeSelector: EditorDynamicDataWrapper(FieldSetCreativeSelector),
    subAccounts: EditorDynamicDataWrapper(FieldSetSubAccounts, { label: 'Subaccount', model: 'settings.subAccountId' }),
    repeater: EditorDynamicDataWrapper(FieldSetRepeater),
    multiLevelCheckboxList: EditorDynamicDataWrapper(FieldSetMultiLevelCheckboxList),
    presetSelector: EditorDynamicDataWrapper(FieldSetPresetSelector),
    dynamicData: EditorDynamicDataWrapper(FieldSetDynamicData),
    dynamicColorList: EditorDynamicDataWrapper(FieldSetDynamicColorList),
    organisationEntitySelect: EditorDynamicDataWrapper(FieldSetOrganisationEntitySelect, { model: 'settings.organisationEntityId' }),
    organisationEntityChecklist: EditorDynamicDataWrapper(FieldSetOrganisationEntityChecklist, { model: 'settings.organisationEntities' }),

    // Channels
    facebookLocation: EditorDynamicDataWrapper(FieldSetFacebookLocation, {
        label: 'Facebook location',
        model: 'settings.publish.facebook.geoLocations'
    }),
    facebookSearch: EditorDynamicDataWrapper(FieldSetFacebookSearch),
    facebookPixelId: EditorDynamicDataWrapper(FieldSetFacebookPixelId, {
        label: 'Facebook Pixel Id',
        model: 'settings.publish.facebook.pixelId'
    }),
    facebookCustomEvent: EditorDynamicDataWrapper(FieldSetFacebookCustomEvent, {
        label: 'Facebook Custom Event',
        model: 'settings.publish.facebook.customEvent'
    }),
    facebookObjective: EditorDynamicDataWrapper(FieldSetFacebookObjective, {
        label: 'Facebook Objective',
        model: 'settings.publish.facebook.objective'
    }),
    facebookCampaignBudget: EditorDynamicDataWrapper(FieldSetFacebookCampaignBudget, {
        label: 'Facebook Campaign Budget',
        model: 'settings.publish.facebook.campaignBudget'
    }),
    facebookAge: EditorDynamicDataWrapper(FieldSetFacebookAge, {
        label: 'Facebook Age',
        model: 'settings.publish.facebook.age'
    }),
    facebookAdSet: EditorDynamicDataWrapper(FieldSetFacebookAdSet, {
        label: 'Facebook Adset',
        model: 'settings.publish.facebook.adSetId'
    }),
    facebookCampaign: EditorDynamicDataWrapper(FieldSetFacebookCampaign, {
        label: 'Facebook Campaign',
        model: 'settings.publish.facebook.campaignId'
    }),
    metadataInput: MetaDataInput,
    googleAds: EditorDynamicDataWrapper(FieldSetGoogleAds),
    googleAdsLocation: EditorDynamicDataWrapper(FieldSetGoogleAdsLocation, {
        label: 'Google Ads locations',
        model: 'settings.publish.googleAds.location'
    }),

    // Campaign settings
    languageSelector: EditorDynamicDataWrapper(FieldSetLanguageSelector, {
        label: 'Language',
        model: 'settings.languages'
    }),
    campaignLanguageSelector: EditorDynamicDataWrapper(FieldSetLanguageSelector, {
        label: 'Language',
        model: 'settings.languages'
    }),
    channelSelector: EditorDynamicDataWrapper(FieldSetCampaignChannelSelector, {
        label: 'Channels',
        model: 'settings.channels'
    }),
    campaignChannelSelector: EditorDynamicDataWrapper(FieldSetCampaignChannelSelector, {
        label: 'Channels',
        model: 'settings.channels'
    }),
    campaignCategorySelector: EditorDynamicDataWrapper(FieldSetCampaignCategorySelector, {
        label: 'Category',
        model: 'settings.category'
    }),
    campaignTags: EditorDynamicDataWrapper(FieldSetCampaignTags, { label: 'Tags', model: 'settings.tags' }),
    decisionTreeOptions: EditorDynamicDataWrapper(FieldSetDecisionTreeOptions, {
        label: 'Decision tree/KPI’s',
        model: 'settings.decisionTree'
    }),
    campaignDecisionTreeOptions: EditorDynamicDataWrapper(FieldSetDecisionTreeOptions, {
        label: 'Decision tree/KPI’s',
        model: 'settings.decisionTree'
    }),

    // Asset selector
    assetGalleryInput: EditorDynamicDataWrapper(FieldSetAssetGalleryInput),
    assetSelector: EditorDynamicDataWrapper(FieldSetAssetSelector),

    // Feed manager
    feedSelect: EditorDynamicDataWrapper(FieldSetFeedSelect),
    feedSelectorInput: EditorDynamicDataWrapper(FieldSetFeedSelectorInput),
    feedMultiSelectorInput: EditorDynamicDataWrapper(FieldSetFeedMultiSelectorInput),

    // Static options
    alert: AlertBox,
    divider: Divider,

    // #if process.env.customer === 'klm' || process.env.customer === 'afkl' || process.env.customer === 'airfrance'
//     KLMPrimaryOriginSelector: EditorDynamicDataWrapper(FieldSetKLMPrimaryOriginSelector),
//     KLMB2BDisplaySelector: EditorDynamicDataWrapper(FieldSetKLMB2BDisplaySelector),
//     KLMDestinationSelector: EditorDynamicDataWrapper(KLMDestinationSelector),
//     KLMOriginSwitch: EditorDynamicDataWrapper(FieldSetKLMOriginSwitch),
//     KLMOriginSelector: EditorDynamicDataWrapper(FieldSetKLMOriginSelector)
    // #endif
};
